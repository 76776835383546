import http from "../http-common"
// http is an alias for an axios object
// 
class ComposerDataService {
  getAll() {
    return http.get("/composers");
  }

  get(id) {
    return http.get(`/composers/${id}`);
  }

  create(data) {
    return http.post("/composers", data);
  }

  update(id, data) {
    return http.put(`/composers/${id}`, data);
  }

  findByName(name) {
    return http.get(`/composers?name=${name}`);
  }

  addToTune(tuneId, composerIdList) {
    return http.put(`/composers/to-tune/${tuneId}`, composerIdList);
  }
}
export default ComposerDataService;
